<template>
  <div
    class="content d-flex flex-col relative"
    :class="{ 'page-calculator': pageIndex === 0 }"
  >
    <h1>Oil & Wax Mass</h1>

    <b-tabs pills v-model="pageIndex" class="d-print-none">
      <b-tab title="Calculator"></b-tab>
      <b-tab title="History"></b-tab>
    </b-tabs>

    <calculator
      v-if="pageIndex === 0"
      @loaded="onLoaded"
      :loadId="loadId"
    />

    <history
      v-if="pageIndex === 1"
      @load="onLoad"
    />
  </div>
</template>

<script>
const Calculator = () => import('@/components/oilwax_calculator/Calculator.vue');
const History = () => import('@/components/oilwax_calculator/History.vue');

export default {
  name: 'OilWaxCalculator',
  components: {
    Calculator,
    History,
  },
  data() {
    return {
      pageIndex: 0,
      loadId: 0,
    };
  },
  methods: {
    onLoad(loadId) {
      this.loadId = loadId;
      this.pageIndex = 0;
    },
    onLoaded() {
      this.loadId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-calculator {
    max-width: 800px;
  }

  @media print {
    .page-calculator {
      margin: 0 auto;
    }
  }
</style>
